export default {
  default: {
    contactSalesProps: {
      label: "Contact Sales",
      url: "/contact-sales"
    },
    requestDemoProps: {
      label: "Demo",
      url: "/demo/request"
    },
    login: {
      buttonLabel: "Login",
      buttonUrl: "https://login.amperity.com/"
    },
    menu: [
      {
        label: "Platform",
        items: [
          {
            links: [
              {
                url: "/platform/customer-data-platform",
                image: "/img/Nav_Featured_Platform.png",
                imageDescription: "Screenshot of the Stitch feature on Amperity.",
                label: "Lakehouse Customer Data Platform",
                description:
                  "Unify and enrich customer data quickly with a CDP that easily plugs into any tech stack."
              }
            ]
          },
          {
            heading: "Platform",
            links: [
              {
                icon: "/icons/ic_nav_Identity-Resolution.svg",
                iconDescription: "AmpID",
                url: "/platform/identity-resolution",
                label: "AmpID",
                description: "Industry-leading identity resolution"
              },
              {
                icon: "/icons/ic_nav_Customer360.svg",
                iconDescription: "Amp 360",
                url: "/platform/customer-360",
                label: "Amp360",
                description: "Accurate & accessible customer profiles"
              },
              {
                icon: "/icons/ic_nav_Intelligence-Activation.svg",
                iconDescription: "AmpIQ",
                url: "/platform/data-intelligence-and-activation",
                label: "AmpIQ",
                description: "Insights, segmentation, and activation"
              },
              {
                icon: "/icons/ic_nav_Amp_AI.svg",
                iconDescription: "AmpAi",
                url: "/platform/ai",
                label: "AmpAi",
                description: "Data exploration and productivity AI",
                isAiLink: true
              },
              {
                icon: "/icons/ic_nav_Data-Ingestion.svg",
                iconDescription: "DataGrid",
                url: "/platform/data-infrastructure",
                label: "DataGrid",
                description: "Customer data operations infrastructure"
              }
            ]
          },
          {
            heading: "Capabilities",
            links: [
              {
                url: "/capabilities/data-warehouse",
                label: "Data Sharing",
                description: "Sync data to a lakehouse without copying"
              },
              {
                url: "/capabilities/real-time-personalization",
                label: "Real-time",
                description: "Deliver more relevant experiences"
              },
              {
                url: "/integrations",
                label: "Integrations",
                description: "Sync data to and from anywhere"
              },
              {
                url: "/capabilities/security-and-privacy",
                label: "Security & Privacy",
                description: "Keep customer data secure and compliant"
              }
            ],
            secondaryHeading: "Patents",
            secondaryLinks: [
              {
                url: "/patents",
                label: "Proprietary Technology",
                description: "Learn how we reinvented data management"
              }
            ]
          }
        ]
      },
      {
        label: "Solutions",
        items: [
          {
            heading: "By Use Case",
            links: [
              {
                url: "/solutions/use-cases/paid-media-optimization",
                label: "Paid Media Optimization"
              },
              {
                url: "/solutions/use-cases/omnichannel-personalization",
                label: "Personalization"
              },
              {
                url: "/solutions/use-cases/elevating-loyalty",
                label: "Loyalty"
              },
              {
                url: "/solutions/use-cases/customer-care",
                label: "Customer Care"
              }
            ]
          },
          {
            heading: "By Function",
            links: [
              {
                url: "/solutions/departments/marketing",
                label: "Marketing"
              },
              {
                url: "/solutions/departments/it",
                label: "Developers"
              },
              {
                url: "/solutions/departments/analytics",
                label: "Analytics"
              }
            ]
          },
          {
            heading: "By Industry",
            links: [
              {
                url: "/solutions/industries/retail",
                label: "Retail"
              },
              {
                url: "/solutions/industries/travel-hospitality",
                label: "Travel & Hospitality"
              },
              {
                url: "/solutions/industries/restaurants",
                label: "Restaurants"
              },
              {
                url: "/solutions/industries/financial-services",
                label: "Financial Services"
              }
            ]
          }
        ]
      },
      {
        label: "Resources",
        items: [
          {
            links: [
              {
                image: "/img/Nav_Featured_Resources.jpg",
                imageDescription: "Guide on teal background",
                url: "/resources",
                label: "All Resources",
                description: "Case studies, downloadable guides, videos, and more."
              }
            ]
          },
          {
            links: [
              {
                label: "RFP Guide",
                url: "/resources/rfp-guide"
              },
              {
                label: "Blog",
                url: "/blog"
              },

              {
                url: "/events",
                label: "Events"
              },
              {
                url: "/resources/webinars",
                label: "Webinars"
              },
              {
                url: "https://docs.amperity.com",
                label: "Documentation"
              }
            ]
          }
        ]
      },
      {
        label: "Customers",
        url: "/customers"
      },
      {
        label: "Partners",
        items: [
          {
            heading: "Become a partner",
            links: [
              {
                url: "/partners",
                label: "Partner Ecosystem"
              },
              {
                url: "/partners/cloud-partners",
                label: "Cloud Partners"
              },
              {
                url: "/partners/technology-partners",
                label: "Technology Partners"
              },
              {
                url: "/partners/solutions-partners",
                label: "Solutions Partners"
              }
            ]
          },
          {
            heading: "Featured Partners",
            links: [
              {
                url: "/partners/databricks ",
                label: "Databricks"
              },
              {
                url: "/partners/snowflake ",
                label: "Snowflake"
              },
              {
                url: "/partners/aws",
                label: "Amazon Web Services"
              },
              {
                url: "/partners/microsoft",
                label: "Microsoft Azure"
              }
            ]
          },
          {
            links: [
              {
                image: "/img/Nav_Featured_Partner_LL.svg",
                imageDescription: "Logo displaying AMP.",
                url: "https://learn.amperity.com/page/partner-learning-homepage",
                label: "Partner Learning Lab",
                description:
                  "Log in to gain access to resources, tools, and information to help you grow your business."
              }
            ]
          }
        ]
      }
    ]
  }
}
